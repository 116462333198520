import { addListener } from 'BaxterScript/version/web/core/Event';
import { setPageTrackers } from 'BaxterScript/version/web/core/State';
import { addScriptContentToHead, addScriptToHead, createScriptElementContent } from 'BaxterScript/helper/browser/Html';
import newRelicMetrics from 'BaxterScript/helper/metrics/BaxterNewRelicMetrics';
import { NewRelicError } from 'BaxterScript/helper/metrics/NewRelicError';
import { Config } from 'BaxterScript/types/Config';

export const webpackExclude = (config: Config): boolean => !config.app?.sati?.enabled;

export const bootstrap = () => {
  const { siteId, excludePaths, scriptPath, scriptType } = globalThis.Baxter.config.app?.sati || {};
  const { pathname } = globalThis.location;
  const satiUrl = '//code3.adtlgc.com/js/sati_init.js';
  const noSurveyUrl = '//code3.adtlgc.com/js/sati_init_no_survey.js';

  // add no survey script to excluded pages
  for (const path of excludePaths) {
    if (pathname.startsWith(path)) {
      addScriptToHead(`baxter-script-sati-nosurvey`, noSurveyUrl);
    }
  }

  // cx call queue
  addScriptContentToHead('baxter-script-sati-callQueue', callQueueContent(siteId));
  // add sati init script
  addScriptToHead(`baxter-script-sati-${siteId}`, scriptPath || satiUrl, { type: scriptType || 'text/plain' });
  // add cxense script
  addScriptContentToHead('baxter-script-sati-cx', cxContent());
  // include script for spa
  addScriptContentToHead('baxter-script-sati-ajax', spaContent(siteId));
  // add meta tags
  metaTags(pathname);

  // add pixel to top of body
  if (/complete|interactive|loaded/.test(document.readyState)) {
    addPixel();
    // invocation
    init();
  } else {
    addListener('DOMContentLoaded', window, addPixel);
    addListener('DOMContentLoaded', window, init);
  }

  setPageTrackers(trackView);
};

const trackView = () => {
  console.info('[SLOTS][SATI][TRACKVIEW]');
  if (typeof globalThis.SATI_TrackView !== 'undefined') {
    globalThis.SATI_TrackView(document.location.href, document.referrer);
  }
};

const callQueueContent = (siteId) =>
  `
    <!--Cxense SATI script 3.0 begin -->
    window.cX= window.cX|| {};
    cX.callQueue= cX.callQueue|| [];
    cX.callQueue.push(['setSiteId', '${siteId}']);
    cX.callQueue.push(['sendPageViewEvent']);
    `;

const cxContent = () =>
  `
    (function(d,s,e,t){e=d.createElement(s);
    e.type='text/java'+s;e.async='async';
    e.src='http'+('https:'===location.protocol?'s://s':'://')+'cdn.cxense.com/cx.js';
    t=d.getElementsByTagName(s)[0];
    t.parentNode.insertBefore(e,t);
    })(document,'script');
    <!--Cxense SATI script 3.0 end -->
    `;

const addPixel = () => {
  try {
    console.info('[SLOTS][SATI][ADDPIXEL]');
    const content = `
        var ad = document.createElement('div');
        ad.id = 'AdSense';
        ad.className = 'AdSense baxter-container';
        ad.style.display = 'block';
        ad.style.position = 'absolute';
        ad.style.top = '-1px';
        ad.style.height = '1px';
        document.body.prepend(ad);
    `;

    const script = createScriptElementContent('baxter-script-sati-pixel', content);
    document.body.prepend(script);
  } catch (e) {
    console.error('[SLOTS][SATI][ADDPIXEL]', e);
    newRelicMetrics.reportError(NewRelicError.SATI_ADD_PIXEL_ERROR, { message: (e as Error).message });
  }
};

const hasPixel = () => document.body.contains(document.getElementById('AdSense'));

const metaTags = (pathName) => {
  const tags = [
    {
      name: 'cXenseParse:pageclass',
      content: pathName.startsWith('/d') ? 'article' : 'frontpage',
    },
    {
      name: 'cXenseParse:url',
      content: document.location.href,
    },
  ];

  for (const tag of tags) {
    const meta = document.createElement('meta');
    meta.name = tag.name;
    meta.content = tag.content;

    const head = document.getElementsByTagName('head')[0];
    head.appendChild(meta);
  }
};

const spaContent = (siteId) =>
  `
    function SATI_TrackView(locationURL, referrerURL) {
        if(locationURL === referrerURL) {return;} window.cX = window.cX || {};
        cX.callQueue = cX.callQueue || [];
        if (typeof cX.callQueue.push === 'function') {
            cX.callQueue.push(['initializePage']);
            cX.callQueue.push(['setSiteId', '${siteId}']);
            cX.callQueue.push(['sendPageViewEvent', { 'location': locationURL, 'referrer':referrerURL}]);
        }
    };
    `;

const init = () => {
  try {
    console.info('[SLOTS][SATI][INIT]');
    const { queryId } = globalThis.Baxter.config.app?.sati || {};
    const content = `
        cX.callQueue.push(['invoke', function () {
            let isAdBlockDetected = !document.getElementById('AdSense').clientHeight ? 'Yes' : 'No';
            cX.setEventAttributes({origin: 'brt-adblock', persistedQueryId: '${queryId}'});
            cX.sendEvent('adBlockerDetection', {adBlockerDetected: isAdBlockDetected});
            document.getElementById('status').innerHTML = isAdBlockDetected;
        }]);
    `;

    const script = createScriptElementContent('baxter-script-sati-invoke', content);
    document.body.appendChild(script);
  } catch (e) {
    console.error('[SLOTS][SATI][INIT]', e);
    newRelicMetrics.reportError(NewRelicError.SATI_INIT_ERROR, { message: (e as Error).message });
  }
};

export default {
  bootstrap,
  addPixel,
  hasPixel,
};
